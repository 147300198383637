
import WebSocketModel from './WebSocketModel.js';


class BGWorkerUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
	}

	async afterInit()
	{
		const socket = new WebSocketModel('/api/bgworker/updates');
		socket.on('message', (msg) => {
			console.log('socket message:',msg);
		});
		window.BGWorkerUpdateSocket = socket;
		socket.open('json');
	}
}

export default BGWorkerUIPlugin;
