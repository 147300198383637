/**
 * @class 
 */
class BGWorkerClient 
{
	/**
	 * Empty constructor
	 */
	constructor ()
	{
	}

	/**
	 * Stop or remove a schedule. If the schedule is running already, it will be stopped. If it hasn't been started, it will be deleted.
	 * @param {Integer} schedule_id = Schedule ID
	 * @return 
	 */
	static async stopOrRemoveSchedule (schedule_id)
	{
		await Grape.fetches.fetch(`/api/bgworker/schedule/${schedule_id}`, {method: 'DELETE'});
	}
}

export default BGWorkerClient;
