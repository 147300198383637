
import template from './ViewProcessSchedules.html';
import BGWorkerClient from '../lib/BGWorkerClient.js';

/**
 * @class ViewProcessViewModel
 */
class ViewProcessViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.page_title = ko.observable('');
		this.is_loading = ko.observable(true);
		this.schedules = ko.observableArray([]);
		this.sort_field = ko.observable('username');
		this.sort_order = ko.observable('ASC');
	}

	expandValues(obj) 
	{
		let html = '<ul>';
		Object.keys(obj).forEach((k) => {
			if (k && typeof k === 'object' && value.constructor === Object)
				html += '<li><span class="object-field-name">' + k + '</span>: ' + expandValues(obj[k]) + '</li>';
			else
				html += '<li><span class="object-field-name">' + k + '</span>: <span class="object-field-value">' + obj[k] + '</span></li>';
		});
		html += '</ul>';
		return html;
	}

	actionFormatter (row)
	{
		let action_buttons = [];
		switch (row.status)
		{
			case 'New':
				action_buttons.push({
					icon: 'fa-remove',
					title: 'Remove Scheduled Process',
					action: () => this.btnStopOrRemove_click(row)
				});
				break;
			case 'Running':
				action_buttons.push({
					icon: 'fa-stop',
					title: 'Stop Running Process',
					action: () => this.btnStopOrRemove_click(row)
				});
				break;
		}
		return action_buttons;
	}

	datetimeFormatter (value)
	{
		if (value)
			return moment(value).format(Grape.config.date_time_format);
		else
			return '-';
	}

	logfileFormatter (schedule_id)
	{
		return `<a href="/api/bgworker/schedules/${schedule_id}/log" target="_blank">Download</a>`;
	}

	statusFormatter (value)
	{
		switch (value)
		{
			case 'Error':
				return `<div style="display: flex; align-items: baseline;"><span class="fa-regular fa-exclamation-triangle" style="color: #E74C3C; margin-right: 10px;"></span><span">${value}</span></div>`;
			case 'New':
				return `<div style="display: flex; align-items: baseline;"><span class="fa-regular fa-file-text" style="color: #91AA9D; margin-right: 10px;"></span><span">${value}</span></span></div>`;
			case 'Completed':
				return `<div style="display: flex; align-items: baseline;"><span class="fa-regular fa-check" style="color: #79BD8F; margin-right: 10px;"></span><span">${value}</span></span></div>`;
			case 'Starting':
			case 'Pending':
			case 'Running':
				return `<div style="display: flex; align-items: baseline;"><span class="fa-regular fa-play" style="color: #00A388; margin-right: 10px;"></span><span">${value}</span></span></div>`;
			default:
				return value;
		}
	}

	btnClose_click ()
	{
		this.dialog.close(true);
	}

	btnRefresh_click ()
	{
		this.dialog.updateData();
	}

	async btnStopOrRemove_click (row)
	{
		await BGWorkerClient.stopOrRemoveSchedule(row.schedule_id);
		await Grape.alerts.alert({
			title: 'Success',
			message: 'Process Schedule successfully removed or stopped',
			type: 'success'
		});
		this.dialog.updateData();
	}

	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
			
		this.dialog.updateData();
	}

}

/**
 * @class ViewProcessSchedulesDialog
 * @implements {GrapeDialogType}
 */
class ViewProcessSchedulesDialog
{
	constructor (bindings)
	{
		this.viewModel = new ViewProcessViewModel(this);
		this.bindings = bindings;
		this.process = this.bindings.process;
	}

	init ()
	{
		this.viewModel.page_title(['Process Schedules:', this.process.description].join(' ') || 'Process Details');
	}

	async updateData ()
	{
		this.viewModel.is_loading(true);
		let options = {
			table: 'v_schedules',
			schema: 'bgworker',
			sortfield: this.viewModel.sort_field(),
			sortorder: this.viewModel.sort_order(),
			filter: [ { field: 'process_id', operator: '=', value: this.process.process_id } ]
		};

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'ERROR')
		{
			Grape.alert_api_error(result);
		}
		else
		{
			if (!result.records)
				result.records = [];

			this.viewModel.schedules(result.records || []);
		}
		this.viewModel.is_loading(false);
	}
}


export default {
	name: 'ViewProcessSchedules',
	dialog_class: ViewProcessSchedulesDialog,
	template: template,
	provider: "ps"
};
